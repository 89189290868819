// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chat-js": () => import("./../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-hotline-js": () => import("./../src/pages/hotline.js" /* webpackChunkName: "component---src-pages-hotline-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-what-js": () => import("./../src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */),
  "component---src-pages-who-js": () => import("./../src/pages/who.js" /* webpackChunkName: "component---src-pages-who-js" */)
}

